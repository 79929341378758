import React from "react";
import "../styles/Footer.css";

const FooterComponent = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <h2>Cleveland Tennis Lessons</h2>
        </div>

        {/* Updated Footer Navigation with animation */}
        <nav className="footer-nav">
          <ul className="footer-menu">
            <li className="footer-menu-item">
              <a href="#home" className="footer-link">Home</a>
            </li>
            <li className="footer-menu-item">
              <a href="#about" className="footer-link">About</a>
            </li>
            <li className="footer-menu-item">
              <a href="#coaching" className="footer-link">Coaching</a>
            </li>
            <li className="footer-menu-item">
              <a href="#contact" className="footer-link">Contact</a>
            </li>
          </ul>
        </nav>
      </div>

      <div className="footer-bottom">
        <p>Kyle Zubricky© All rights reserved.</p>
      </div>
    </footer>
  );
};

export default FooterComponent;
