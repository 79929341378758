// App.jsx
import React from 'react';
import './App.css'; // Ensure this file exists and contains your styles

// Import all the sections/components needed for the landing page
import HomeSection from './components/HomeSection';
import AboutSection from './components/AboutSection';
import CoachingSection from './components/CoachingSection';
import ContactSection from './components/ContactSection';
import EnrollmentForm from './components/EnrollmentForm';
import Navbar from './components/Navbar';
import Footer from './components/Footer';


function App() {
  return (
    <div className="App">
      <Navbar />
  
      <section id="home">
        <HomeSection />
        
      </section>

      <section id="about">
        <AboutSection />
      </section>

      <section id="coaching">
        <CoachingSection />
      </section>

      <section id="enroll">
        <EnrollmentForm />
      </section>

      <section id="contact">
        <ContactSection />
      </section>

      <section id="contact">
        <Footer />
      </section>
    </div>
  );
}

export default App;
