import React from "react";
import "../styles/CoachingSection.css";
import Westlake from "../assets/westlake.jpg";
import Berea from "../assets/berea.jpg";
import Lakewood from "../assets/lakewood3.jpg";
import Cleveland from "../assets/ctl_cleveland.jpg"


const CoachingSection = () => {
  return (
    <section className="coaching-section">
      {/* Header Section */}
      <div className="coaching-header">
        <h1>Elevate Your Tennis Game</h1>
        <p>Professional coaching tailored for all skill levels.</p>
      </div>

      {/* Description Section */}
      <div className="coaching-content">
        <div className="coaching-box">
          <div className="coaching-box-content">
            <h2>Private Coaching</h2>
            <p>Personalized coaching sessions designed to enhance your skills and strategies on the court.</p>
          </div>
        </div>

        <div className="coaching-box coaching-box-right">
          <div className="coaching-box-content">
            <h2>Group Coaching</h2>
            <p>Engage with others in a group setting to practice drills, learn techniques, and challenge your abilities.</p>
          </div>
        </div>
      </div>

      {/* Pricing Section */}
      <div className="pricing-section">
        <h2>Pricing</h2>
        <div className="pricing-details">
          <div className="pricing-card">
            <h3>1 Hour Lesson</h3>
            <p>$70 per session</p>
            <p>All ages welcome</p>
          </div>
          <div className="pricing-card">
            <h3>Package Deals</h3>
            <p>5 Sessions - $320</p>
            <p>10 Sessions - $600</p>
          </div>
        </div>
      </div>

      {/* Skills You'll Learn Section */}
      <div className="skills-section">
        <h2>Skills You'll Learn</h2>
        <ul className="skills-list">
          <li>Proper Tennis Racquet Grip</li>
          <li>Forehand and Backhand Techniques</li>
          <li>Serve and Volley Mastery</li>
          <li>Footwork and Court Positioning</li>
          <li>Strategy and Game Play Tactics</li>
        </ul>
      </div>

      {/* Locations Section */}
      <div className="locations-section">
        <div className="location-content">
          <h2>Coaching Locations</h2>
          <p>Lessons will be held in Cleveland <strong>(Downtown Cleveland Indoors)</strong>.
          </p>
          <div className="location-images">
            <img src={Cleveland} alt="Cleveland" className="location-image" />
            <img src={Westlake} alt="Westlake" className="location-image" />
            <img src={Lakewood} alt="Lakewood" className="location-image" />
            <img src={Berea} alt="Berea" className="location-image" />
          </div>
        </div>
        <div className="map">
            <iframe width="100%" height="420" frameborder="0" scrolling="no" borderradius="50" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=300&amp;hl=en&amp;q=Cleveland+()&amp;t=&amp;z=9&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps tracker sport</a></iframe>
        </div>
      </div>

      {/* What to Expect Section */}
      <div className="testimonials-section">
        <h2>What to Expect</h2>
        <p>No contracts or membership needed, no initiation fee either - you can book one lesson stop after one lesson if you aren’t pleased.</p>
        <p>If you have never played tennis before, I do have extra tennis racquets you can borrow and use for the duration of the lesson.</p>
        <p>For Beginners, I will go over how to hold the tennis racquet, how to swing it, and how to hit the tennis ball.</p>
        <p>For intermediate level players, we will work on serving technique, forehand, backhand, and volleying.</p>
      </div>

    </section>
  );
};

export default CoachingSection;
