import React, { useState, useEffect } from 'react';
import '../styles/Navbar.css';
import Ctllogo from '../assets/ctl_logo1-removebg-preview.png';

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Effect to handle screen resizing and reset the menu
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMobileMenuOpen(false); 
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const scrollToEnrollmentForm = () => {
    const enrollmentFormSection = document.getElementById('enroll');
    if (enrollmentFormSection) {
      enrollmentFormSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <nav className="navbar">
      {/* Hamburger icon for mobile */}
      <div className={`hamburger ${isMobileMenuOpen ? 'active' : ''}`} onClick={handleMenuToggle}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
      {/* Logo or brand name */}
      <div className="logo">
        <img src={Ctllogo} alt="Logo" />
      </div>
      {/* Navigation links */}
      <ul className={`nav-links ${isMobileMenuOpen ? 'active' : ''}`}>
        <li><a href="#home">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#coaching">Coaching</a></li>
        <li><a href="#contact">Contact</a></li>
        <li>
          <button
            className="book-button"
            onClick={scrollToEnrollmentForm}
          >
            Book Now
          </button>
        </li>
      </ul>

    </nav>
  );
}

export default Navbar;
