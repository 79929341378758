import React, { useState } from 'react';
import '../styles/EnrollmentForm.css';
import Cashapp from "../assets/cashapp_icon2.png";
import Venmo from "../assets/venmo_icon.png";
import Zelle from "../assets/Zelle1.svg";
import Paypal from "../assets/paypal_icon.png";
import TextIcon from "../assets/text.png"; 
import EmailIcon from "../assets/email.png";
import CallIcon from "../assets/phone-call.png";
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

const EnrollmentForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    message: '',
    contactMethod: 'email',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      phone: formData.phone,
      email: formData.email,
      message: formData.message,
      contactMethod: formData.contactMethod, 
    };

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      templateParams,
      process.env.REACT_APP_EMAILJS_USER_ID
    )
    .then((response) => {
      Swal.fire({
        icon: 'success',
        title: 'Enrollment Sent!',
        text: 'Your enrollment was sent. I will reach out to you soon.',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      });
      setFormData({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        message: '',
        contactMethod: 'email'
      });
    })
    .catch((error) => {
      Swal.fire({
        icon: 'error',
        title: 'Oops!',
        text: 'Failed to send the enrollment. Please try again. If problem persists call/text my number 440-915-2518.',
        confirmButtonColor: '#d33',
        confirmButtonText: 'Retry'
      });
    });
  };

  return (
    <section className="enrollment-section">
      <form className="enrollment-form" onSubmit={handleSubmit}>
        <div className="form-section">
          <h2>Lesson Request Form</h2>
          <p>
            Fill out and submit your lesson request with the form below. I’ll be in touch shortly after on what times and dates work best based on your preference selections.
          </p>
        </div>

        {/* First Name Field */}
        <div className="form-group">
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="Enter your first name"
          />
        </div>

        {/* Last Name Field */}
        <div className="form-group">
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Enter your last name"
          />
        </div>

        {/* Phone Field */}
        <div className="form-group">
          <label htmlFor="phone">Phone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Enter your phone number"
          />
        </div>

        {/* Email Field */}
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email address"
          />
        </div>

        {/* Contact Method Field */}
        <div className="form-group">
          <label>Preferred Contact Method:</label>
          <div className="contact-method-options">
            <label className={`contact-option ${formData.contactMethod === 'text' ? 'selected' : ''}`} htmlFor="textOption">
              <input
                type="radio"
                id="textOption"
                name="contactMethod"
                value="text"
                checked={formData.contactMethod === 'text'}
                onChange={handleChange}
              />
              <img src={TextIcon} alt="Text" className="contact-icon" />
              Text
            </label>
            <label className={`contact-option ${formData.contactMethod === 'email' ? 'selected' : ''}`} htmlFor="emailOption">
              <input
                type="radio"
                id="emailOption"
                name="contactMethod"
                value="email"
                checked={formData.contactMethod === 'email'}
                onChange={handleChange}
              />
              <img src={EmailIcon} alt="Email" className="contact-icon" />
              Email
            </label>
            <label className={`contact-option ${formData.contactMethod === 'call' ? 'selected' : ''}`} htmlFor="callOption">
              <input
                type="radio"
                id="callOption"
                name="contactMethod"
                value="call"
                checked={formData.contactMethod === 'call'}
                onChange={handleChange}
              />
              <img src={CallIcon} alt="Call" className="contact-icon" />
              Call
            </label>
          </div>
        </div>


        {/* Message Field */}
        <div className="form-group">
          <label htmlFor="message">Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Type your message here"
            rows="5"
          ></textarea>
        </div>

        {/* Submit Button */}
        <button type="submit" className="submit-button">Submit</button>

        {/* Payment Options Section */}
        <div className="payment-options">
          <h2>Payment Options</h2>
          <p><strong>Cash</strong>, Venmo, Paypal, Cashapp, Zelle</p>
          <div className="payment-icons">
            <a href="https://account.venmo.com/u/Kyle-Zubricky?catchAll=u&catchAll=Kyle-Zubricky">
              <img src={Venmo} alt="Venmo" />
            </a>
            <a href="https://www.paypal.com">
              <img src={Paypal} alt="PayPal" />
            </a>
            <a href="https://cash.app">
              <img src={Cashapp} alt="CashApp" />
            </a>
            <a href="https://zelle.com">
              <img src={Zelle} alt="Zelle" />
            </a>
          </div>
        </div>
      </form>
    </section>
  );
};

export default EnrollmentForm;
